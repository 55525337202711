import {  FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Box,
    Container
     } from '@mui/material';
import React, { useEffect,useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch ,useSelector} from 'react-redux';
import toast from "react-hot-toast";
import { changePassword, userFlagOff } from '../../Redux/Actions/userAction';
import Loader from '../../components/loader/Loader';
import loginBackgroundIMG from "../../Assets/images/login-background.jpeg";
function ChangePassword() {
    const dispatch=useDispatch();
    const {isPasswordChanging,isPasswordChanged,isTimeOver,done}=useSelector(state=>state.User);
    const params=useParams();
    const [password,setPassword]=useState('');
    const [type, setType] = useState(true);
    useEffect(()=>{
      if(isPasswordChanged){
          toast.success('Password have been changed!');
          dispatch(userFlagOff());
      }
      if(isTimeOver){
          toast.error('Time is over, please send again request.');
          dispatch(userFlagOff());
      }
    },[isPasswordChanged,isTimeOver])
    const submit=()=>{
         dispatch(changePassword({
            id:params.id,
            token:params.token,
            password
        }))
    }
  return (
    <>
<Box
sx={{
  minHeight: "100vh",
  maxWidth: "100vw",
  display: "flex",
  position: "relative",
  margin: 0,
  padding: 0,
  boxSizing: "border-box",
}}
>
  {
         isPasswordChanging && <>
         <Box sx={{
          position:'absolute',
          height:'100vh',
          width:'100vw',
          background:'rgba(0,0,0,0.5)',
          top:0,
          left:0,
          right:0,
          bottom:0,
          zIndex:'1000000',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}>
         <Loader/>
          {/* <img src="/images/spiner.gif"/> */}
        </Box>
         </>
       }
 <Box
          sx={{
            height: "100dvh",
            width: "70dvw",
            // border: "1px solid black",
            backgroundImage: `url(${loginBackgroundIMG})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: { lg: "block", md: "block", sm: "none", xs: "none" },
          }}
        ></Box>
        <Box
          sx={{
            height: "100dvh",
            width: {
              xl: "30dvw",
              lg: "30dvw",
              md: "30dvw",
              sm: "100dvw",
              xs: "100dvw",
            },
            backgroundImage: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "none",
            },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            // border: "1px solid black",
          }}
        >
<Container
maxWidth={'xl'}
>
  <Box
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    position: "relative",
  }}
  >
<Box
 sx={{
  width: { lg: "100%", md: "100%", sm: "73%", xs: "73%" },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}}
>

{/* <div className="inner-container"> */}
          <div className="top-box">
            <div className="login-box">
              <div className="forget-password-form">
                <div className="login-form-header">
                  <div className="logo-sec">
                    <div className="logo">
                      <img src="/images/LOGO-SNS.png" />
                    </div>
                  </div>
                </div>
                {
                    done ? <>
                    <div className="title">
                    <div className="login-icon"></div>
                    <div className="title-text">Password Changed.</div>
                  </div>
                  <div className='other-controlls'>
                  <Link to={'/login'}>Go to login</Link>
                  </div>
                    
                    </> :
                     <>
                    <div className="login-form-body">
                  <div className="title">
                    <div className="login-icon"></div>
                    <div className="title-text">Reset Password</div>
                  </div>
                  <div className="input-fields">
                  <FormControl variant={"standard"} fullWidth>
                        <InputLabel htmlFor="password">New Password</InputLabel>
                        <Input
                          id="password"
                          type={type ? "password" : "text"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment
                              position={"end"}
                              style={{ marginTop: "-10px", color: "var(--primary-color)" }}
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setType(!type)}
                              >
                                {type ? (
                                  <VisibilityOffIcon
                                    style={{ color: "var(--primary-color)" }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    style={{ color: "var(--primary-color)" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                  </div>
                  <div className="button-field">
                    <button type={'button'} className="btn-login" onClick={submit}>
                      send
                    </button>
                  </div>
                  <div className="resend-email flex justifyContentCenter alignItemCenter">
                      Resend <Link to={'/forgetpassword'}>Back to Forgetpassword</Link>
                  </div>
                </div> 
                    </>
                }
               

              </div>
            </div>
            {/* <div className="text-box">
              <div className="text-block">
                <p className="main-heading">We Make Your Business Digitalize</p>
                <p className="sub-heading">
                    If you have question please contact support@leedoit.com?
                  </p>
              </div>
            </div> */}
          </div>
          {/* <div className="bottom-box"></div> */}
        {/* </div> */}
</Box>
  </Box>
</Container>
        </Box>
</Box>



     {/* <div className="container">
        <div className="eclips-1"></div>
        <div className="eclips-2"></div>
        <div className="eclips-3"></div>
      
      </div> */}
    </>
  )
}

export default ChangePassword
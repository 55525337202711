import { api } from "../../api/api";
import {
  GET_USER_REQUEST,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  USER_SAVE_SUCCESS,
  LOGOUT,
  USER_FLAG_OFF,
  USER_SAVE_FAILED,
  USER_DELETE_SUCCESS,
  UPDATE_USER,
  LOGIN_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  REFRESH_LOGIN,
  EMAIL_TRANSFORED,
  EMAIL_TRANSFORED_FAILED,
  EMAIL_TRANSFOR_REQUEST,
  BLOCKED_USER,
  UNBLOCKED_USER
} from "../constant";

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: ''
    })
    const user = await api.post(`/user/login`, {
      email: email,
      password: password,
    });
    if (user.status === 201) {
      if (user.data.auth) {
        dispatch({ type: LOGIN_SUCCESS, payload: user.data });
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload: user.data,
        });
      }
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    const user = await api.get('/user/logout');
    // window.localStorage.removeItem('leedoitLogin');
    dispatch({
      type: LOGOUT,
      payload: "",
    });
  };
};
export const userRegister = (obj) => {
  return async (dispatch) => {
    const user = await api.post(`/user/register`, obj);
    if (user.status === 201) {
      user.data.isRegistered
        ? dispatch({
          type: USER_SAVE_SUCCESS,
          payload: user.data.user,
        })
        : dispatch({
          type: USER_SAVE_FAILED,
          payload: "",
        })
    }
  };
};
export const userFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_FLAG_OFF,
      payload: "",
    });
  };
};
export const getUsers = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_REQUEST,
      payload: "",
    });
    const user = await api.get(`/user/getusers/${id}`);
    if (user.status === 200) {
      if (user.data.users.length > -1) {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: user.data.users,
        });
      } else {
        dispatch({
          type: GET_USER_FAILED,
          payload: "",
        });
      }
    }
  };
};
export const deleteUser = (id) => {
  return async (dispatch) => {
    const user = await api.get(`/user/deleteuser/${id}`);
    // console.log(user)
    if (user.status === 201) {
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: user.data.user._id
      })
    }
  }
}
export const updateUser = (id, obj) => {
  return async (dispatch) => {
    const updatedUser = await api.patch(`/user/updateuser/${id}`, obj)
    // console.log(updatedUser.data.updatedUser)
    if (updatedUser.status === 201) {
      dispatch({
        type: UPDATE_USER,
        payload: updatedUser.data.updatedUser
      })
    }
  }
}
export const forgetPassword = (email) => {
  return async (dispatch) => {
    dispatch({
      type: EMAIL_TRANSFOR_REQUEST,
      payload: ''
    })
    const user = await api.post(`/user/forgetpassword`, { email: email })
    if (user.status === 201) {
      if (user.data.validUser) {
        dispatch({
          type: EMAIL_TRANSFORED,
          payload: ''
        })
      }
      else {
        dispatch({
          type: EMAIL_TRANSFORED_FAILED,
          payload: ''
        })
      }
    }
  }
}
export const changePassword = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
      payload: ''
    })
    const response = await api.patch(`/user/changepassword/${obj.id}/${obj.token}`, { newpassword: obj.password })
    try {
      if (response.status === 201) {
        // if(response.data.timeup){
        //   dispatch({
        //     type:CHANGE_PASSWORD_FAILED,
        //     payload:''
        //   })
        // }
        if (response.data.timeup === false) {
          dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: response.data.updatedPassword
          })
        }
      }
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        payload: ''
      })
    }
  }
}
export const refreshLogin = (status, data) => {
  return async (dispatch) => {
    if (status === 201) {
      if (data.auth) {
        dispatch({ type: REFRESH_LOGIN, payload: data });
      }
    }
  }
}
export const statusOfUser = (id, _status) => {
  return async (dispatch) => {
    if (_status === 'block') {
      const { status, data } = await api.patch(`/user/blockuser/${id}`);
      if (status === 201) {
        dispatch({
          type: BLOCKED_USER,
          payload: data.statusUser
        })
      }
    }
    if (_status === 'unblock') {
      const { status, data } = await api.patch(`/user/unblockuser/${id}`);
      if (status === 201) {
        dispatch({
          type: UNBLOCKED_USER,
          payload: data.statusUser
        })
      }
    }
  }
}

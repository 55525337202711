import {
  ADD_PLAYLIST_SUCCESS,
  DELETE_CONTENT_FROM_PLAYLIST,
  ADD_CONTENT_VALUE,
  GET_PLAYLIST_REQUEST,
  ADD_CONTENT_IN_PLAYLIST,
  PLAYLIST_SCREEN_CHECK,
  DELETE_PLAYLIST,
  ADD_PLAYLIST_REQUEST,
  GET_PLAYLIST_SUCCESS,
  DELETE_CONTENT_VALUE,
  UPDATE_DURATION,
  GET_PLAYLIST_FAILED,
  PLAYLIST_FLAG_OFF,
  PLAYLIST_SCHEDULE_SUCCESS,
  PLAYLIST_SCHEDULE_DELETE,
  CLIENT_PLAYLIST_SUCCESS,
  PLAYLIST_CONTNET_SORTING,
} from "../constant";

const initial = {
  isPlaylistLoading: false,
  isPlaylistAdded: false,
  isPlaylistAdding: false,
  isPlaylistDeleted: false,
  isPlaylistUpdated: false,
  isPlaylistScheduled: false,
  isPlaylistScheduledDeleted: false,
  type:'',
  isSorted:false,
  playlist: [],
  content: [],
  clientPlaylist:[]
};
export const playlistReducer = (state = initial, action) => {
  switch (action.type) {
    case PLAYLIST_FLAG_OFF:
      return {
        ...state,
        isPlaylistAdded: false,
        isPlaylistDeleted: false,
        isPlaylistUpdated: false,
        isPlaylistScheduled: false,
        isPlaylistScheduledDeleted: false,
        isSorted:false,
        type:''
      };
    case ADD_PLAYLIST_REQUEST:
      return {
        ...state,
        isPlaylistAddeding: true,
      };
    case ADD_PLAYLIST_SUCCESS:
      return {
        ...state,
        isPlaylistAddeding: false,
        isPlaylistAdded: true,
        playlist: [...state.playlist, { ...action.payload, screenCheck: true }],
      };
    case GET_PLAYLIST_REQUEST:
      return {
        ...state,
        isPlaylistLoading: true,
      };
    case GET_PLAYLIST_SUCCESS:
      let temp = [];
      action.payload.forEach((p) => {
        temp.push({ ...p, screenCheck: true });
      });
      return {
        ...state,
        isPlaylistLoading: false,
        playlist: temp,
      };
    case GET_PLAYLIST_FAILED:
      return {
        ...state,
        isPlaylistLoading: false,
      };

    case ADD_CONTENT_VALUE:
      return {
        ...state,
        content: [...state.content, action.payload],
      };
    case DELETE_CONTENT_VALUE:
      return {
        ...state,
        content: state.content.filter((v) => v.id !== action.payload),
      };
    case ADD_CONTENT_IN_PLAYLIST:
      const addContentPlaylistIndex = state.playlist.findIndex(
        (p) => p._id === action.payload._id
      );
      state.playlist[addContentPlaylistIndex] = {
        ...action.payload,
        screenCheck: true
      };
      return {
        ...state,
        isPlaylistUpdated: true,
        type:ADD_CONTENT_IN_PLAYLIST,
        playlist: state.playlist,
      };
    case PLAYLIST_SCREEN_CHECK:
      const checkArrIndex = state.playlist.findIndex(
        (p) => p._id === action.payload._id
      );
      state.playlist[checkArrIndex] = { ...action.payload, screenCheck: false };
      return {
        ...state,
        playlist: state.playlist,
      };
    case DELETE_CONTENT_FROM_PLAYLIST:
      const deleteArrIndex = state.playlist.findIndex(
        (p) => p._id === action.payload.playlistId
      );
      state.playlist[deleteArrIndex] = {
        ...state.playlist[deleteArrIndex],
        content: state.playlist[deleteArrIndex].content.filter(
          (c) => c._id !== action.payload.contentId
        ),
      };
      return {
        ...state,
        isPlaylistUpdated: true,
        type:DELETE_CONTENT_FROM_PLAYLIST,
        playlist: state.playlist,
      };
    case DELETE_PLAYLIST:
      return {
        ...state,
        isPlaylistUpdated: true,
        type:DELETE_PLAYLIST,
        playlist: state.playlist.filter((p) => p._id !== action.payload._id),
      };
    case UPDATE_DURATION:
      const time = action.payload.time.split(":");
      const minmilli = time[0] * 1000 * 60;
      const secmilli = time[1] * 1000;

      const updateTimePlaylistIndex = state.playlist.findIndex(
        (p) => p._id === action.payload.playlistId
      );
      const updateTimeContentIndex = state.playlist[
        updateTimePlaylistIndex
      ].content.findIndex((c) => c._id === action.payload.contentId);

      const playlists = state.playlist[updateTimePlaylistIndex];
      playlists.content[updateTimeContentIndex] = {
        ...playlists.content[updateTimeContentIndex],
        duration: minmilli + secmilli,
      };

      state.playlist[updateTimePlaylistIndex] = { ...playlists };
      return {
        ...state,
        isPlaylistUpdated: true,
        type:UPDATE_DURATION,
        playlist: state.playlist,
      };
    case PLAYLIST_SCHEDULE_SUCCESS:
      const findIndexOfPlaylist = state.playlist.findIndex(p => p._id === action.payload._id);
      state.playlist[findIndexOfPlaylist] = { ...action.payload };
      return {
        ...state,
        // isPlaylistScheduled: true,
        isPlaylistUpdated:true,
        type:PLAYLIST_SCHEDULE_SUCCESS,
        playlist: state.playlist
      }
    case PLAYLIST_SCHEDULE_DELETE:
      const _findIndexOfPlaylist = state.playlist.findIndex(p => p._id === action.payload._id);
      state.playlist[_findIndexOfPlaylist] = { ...action.payload };
      return {
        ...state,
        isPlaylistUpdated: true,
        type:PLAYLIST_SCHEDULE_DELETE,
        playlist: state.playlist
      }
    case CLIENT_PLAYLIST_SUCCESS:
      return {
        ...state,
        clientPlaylist: action.payload
      }
      case PLAYLIST_CONTNET_SORTING:
      const _playlistIndex= state.playlist.findIndex(p=>p._id==action.payload.playlistId)
      const _contentList=state.playlist[_playlistIndex].content;
      const _from=_contentList.findIndex(c=>c._id==action.payload.from);
      const _to=_contentList.findIndex(c=>c._id==action.payload.to);
      _contentList.splice(_to,0,_contentList.splice(_from,1)[0]);
      state.playlist[_playlistIndex]={...state.playlist[_playlistIndex],content:_contentList}
      return {
         ...state,
         playlist:state.playlist,
         isPlaylistUpdated:true,
         type:PLAYLIST_CONTNET_SORTING
        }
    default:
      return state;
  }
};

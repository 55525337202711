export const ADD_PLAYLIST_REQUEST='ADD_PLAYLIST_REQUEST';
export const ADD_PLAYLIST_SUCCESS='ADD_PLAYLIST_SUCCESS';
export const ADD_PLAYLIST_FAIL='ADD_PLAYLIST_FAIL';
export const GET_PLAYLIST_REQUEST='GET_PLAYLIST_REQUEST';
export const GET_PLAYLIST_SUCCESS='GET_PLAYLIST_SUCCESS';
export const GET_PLAYLIST_FAILED='GET_PLAYLIST_SUCCESS';
export const ADD_CONTENT_IN_PLAYLIST='ADD_CONTENT_IN_PLAYLIST';
export const ADD_SCREEN_TO_PLAYLIST='ADD_SCREEN_TO_PLAYLIST';
export const PLAYLIST_SCREEN_CHECK='PLAYLIST_SCREEN_CHECK';
export const DELETE_CONTENT_FROM_PLAYLIST='DELETE_CONTENT_FROM_PLAYLIST';
export const DELETE_PLAYLIST='DELETE_PLAYLIST';
export const UPDATE_DURATION='UPDATE_DURATION';
export const PLAYLIST_FLAG_OFF='PLAYLIST_FLAG_OFF';
export const PLAYLIST_SCHEDULE_REQUEST='PLAYLIST_SCHEDULE_REQUEST';
export const PLAYLIST_SCHEDULE_SUCCESS='PLAYLIST_SCHEDULE_SUCCESS';
export const PLAYLIST_SCHEDULE_DELETE='PLAYLIST_SCHEDULE_DELETE';
export const PLAYLIST_CONTNET_SORTING='PLAYLIST_CONTNET_SORTING';
// screens
export const SCREEN_REQUEST='SCREEN_REQUEST';
export const SCREEN_REQUEST_SUCCESS='SCREEN_REQUEST_SUCCESS';
export const SCREEN_REQUEST_FAILED='SCREEN_REQUEST_FAILED';
export const ADD_SCREEN_REQUEST='ADD_SCREEN_REQUEST';
export const ADD_SCREEN_SUCCESS='ADD_SCREEN_SUCCESS';
export const ADD_SCREEN_FAIL='ADD_SCREEN_FAIL';
export const SCREEN_STATUS_ACTIVE='SCREEN_STATUS_ACTIVE';
export const SCREEN_STATUS_DEACTIVE='SCREEN_STATUS_ACTIVE';
export const DELETE_SCREEN='DELETE_SCREEN';
export const ADD_PLAYLIST_TO_SCREEN='ADD_PLAYLIST_TO_SCREEN';
export const UNCHECK_ASSIGNED_SCREEN='UNCHECK_ASSIGNED_SCREEN';
export const SCREEN_FLAG_OFF='SCREEN_FLAG_OFF';
export const FIND_ONE_SCREEN='FIND_ONE_SCREEN';
export const UPDATE_SCREEN='UPDATE_SCREEN';
export const UPDATE_SCREEN_SUCCESS='UPDATE_SCREEN_SUCCESS';
// checkbox handle
export const ADD_CONTENT_VALUE='ADD_CONTENT_VALUE';
export const DELETE_CONTENT_VALUE='DELETE_CONTENT_VALUE';
export const EMPTY_CONTENT_FORM='EMPTY_CONTENT_FORM';
export const COPY_CONTENT_SUCCESS='COPY_CONTENT_SUCCESS';
// add content
export const ADD_CONTENT_REQUEST='ADD_CONTENT_REQUEST';
export const ADD_CONTENT='ADD_CONTENT';
export const GET_CONTENT_REQUEST='GET_CONTENT_REQUEST';
export const GET_CONTENT_SUCCESS='GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILED='GET_CONTENT_FAILED';
export const DELETE_CONTENT_REQUEST='DELETE_CONTENT_REQUEST';
export const DELETE_CONTENT_REQUEST_SUCCESS='DELETE_CONTENT_REQUEST_SUCCESS';
export const CHECK_ALL_CONTENT='CHECK_ALL_CONTENT';
export const UNCHECK_ALL_CONTENT='UNCHECK_ALL_CONTENT';
export const CHECK_ONE_CONTENT='CHECK_ONE_CONTENT';
export const UNCHECK_ONE_CONTENT='UNCHECK_ONE_CONTENT';
export const DELETE_ALL_CONTENT='DELETE_ALL_CONTENT';
export const CONTENT_FLAG_OFF='CONTENT_FLAG_OFF';
export const SEARCHING='SEARCHING';
export const SEARCHING_END='SEARCHING_END';
export const CONTENT_SCHEDULE_SUCCESS='CONTENT_SCHEDULE_SUCCESS';
export const CONTENT_SCHEDULE_DELETE='CONTENT_SCHEDULE_DELETE';
// user handler
export const LOGIN_REQUEST="LOGIN_REQUEST";
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGIN_FAILED='LOGIN_FAILED';
export const USER_SAVE_SUCCESS='USER_SAVE_SUCCESS';
export const USER_SAVE_FAILED='USER_SAVE_FAILED';
export const GET_USER_REQUEST='GET_USER_REQUEST';
export const GET_USER_SUCCESS='GET_USER_SUCCESS';
export const GET_USER_FAILED='GET_USER_FAILED';
export const LOGOUT='LOGOUT';
export const USER_FLAG_OFF='USER_FLAG_OFF';
export const USER_DELETE_SUCCESS='USER_DELETE_SUCCESS';
export const UPDATE_USER='UPDATE_USER';
export const CHANGE_PASSWORD_REQUEST='CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS='CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED='CHANGE_PASSWORD_FAILED';
export const REFRESH_LOGIN='REFRESH_LOGIN';
export const EMAIL_TRANSFOR_REQUEST='EMAIL_TRANSFOR_REQUEST';
export const EMAIL_TRANSFORED='EMAIL_TRANSFORED';
export const EMAIL_TRANSFORED_FAILED='EMAIL_TRANSFORED_FAILED';


export const BLOCKED_USER='BLOCKED_USER';
export const UNBLOCKED_USER='UNBLOCKED_USER';
export const CONTACT_TO_ADMIN_ON_USER_BLOCKED='CONTACT_TO_ADMIN_ON_USER_BLOCKED';
export const CLIENT_CONTENT_REQUEST='CLIENT_CONTENT_REQUEST';
export const CLIENT_CONTENT_SUCCESS='CLIENT_CONTNET_SUCCESS';
export const CONTENT_UPDATED='CONTENT_UPDATED';
export const CLIENT_SCREENS_REQUEST='CLIENT_SCREENS_REQUEST';
export const CLIENT_SCREENS_SUCCESS='CLIENT_SCREENS_SUCCESS';
export const CLIENT_PLAYLIST_REQUEST='CLIENT_PLAYLIST_REQUEST';
export const CLIENT_PLAYLIST_SUCCESS='CLIENT_PLAYLIST_SUCCESS';

// DESIGN EDITOR
export const SET_LENGTH_OF_CANVA='SET_LENGTH_OF_CANVA';
export const SET_BACKGROUND_COLOR_OF_CANVA='SET_BACKGROUND_COLOR_OF_CANVA';
export const SET_TEXT_FONT_COLOR='SET_TEXT_FONT_COLOR';

import React from 'react'
import './index.css';
function CustomCheckbox({ rememberme, checkedRememberMe }) {
  return (
    <>
      {/* <div className='custom-checkbox'> */}
      <input
        checked={rememberme}
        onChange={checkedRememberMe}
        id={"main-checkbox"}
        type={"checkbox"}
        style={{ display: "none" }}
        hidden
      />
      <label htmlFor="main-checkbox" className="main--checkbox"></label>
      {/* </div> */}
    </>
  );
}

export default CustomCheckbox

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addScreenToPlaylist,
  deletePlaylist,
  playlistScreenCheck,
} from "../../../../Redux/Actions/playlistAction";
import { getScreens } from "../../../../Redux/Actions/screenAction";
import "./playlistcard.css";
// import { ReactComponent as DeleteIcon } from "../../../../Assets/images/delete.svg";
// import { ReactComponent as CreateIcon } from "../../../../Assets/images/createicon.svg";
import { Box, Menu, MenuItem } from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  DeleteOutlineOutlined,
  PlaylistAddOutlined,
} from "@mui/icons-material";
import PlaylistContentCardItem from "../content-card/PlaylistContentCardItem";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function PlaylistCard(props) {
  const dispatch = useDispatch();
  const { screens } = useSelector((state) => state.Screen);
  const [code, setCode] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
    // setOpenProfileDialog(true);
  };
  useEffect(() => {
    dispatch(getScreens());
    // console.log(props);
  }, []);
  const newCheck = (c) => {
    const id = setTimeout(() => {
      const screen = screens.find((s) => s._id === c);
      // console.log(screen);
      if (screen !== null) {
        props.socketRef.current?.emit("playlist_added", {
          detail: { mac: screen?.mac, code: c },
        });
        clearTimeout(id);
      }
    }, 2000);
  };
  return (
    <>
      <div className="playlist-cards">
        <div className="playlist-cards-top">
          <div className="playlist-schedule-icon flex justifyContentCenter alignItemCenter"></div>
          <div className="playlist-card-body">
            {/* {props.children} */}
            {/* <DndProvider backend={HTML5Backend}> */}
              {props.play.content.map((v, index) => (
                <PlaylistContentCardItem
                  key={index}
                  content={v}
                  deleteContent={props.deleteContent}
                  addSchedule={props.addSchedule}
                  updateTimeHandler={props.updateTimeHandler}
                  playID={props.play._id}
                />
              ))}
            {/* </DndProvider> */}
          </div>
        </div>
        <div className="playlist-cards-bottom flex justify-content-space-between alignItemCenter">
          <div className="add-content-playlist flex justifyContentCenter alignItemCenter">
            <PlaylistAddOutlined
              style={{ color: "var(--primary-color)", cursor: "pointer" }}
              onClick={() =>
                props.addContentModel(
                  props.play._id,
                  props.play?.activescreen?.mac
                )
              }
            />
            <span>Create</span>
          </div>
          <Box>{props.play.name}</Box>
          <div className="delete-playlist flex justifyContentCenter alignItemCenter">
            <DeleteOutlineOutlined
              onClick={() =>
                props.deleteWholePlaylist(
                  props.play._id,
                  props.play?.activescreen?.mac
                )
              }
              sx={{ color: "var(--primary-color)", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlaylistCard;
